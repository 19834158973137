.dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 20px;
  border: 3px blue dashed;
  height: 200px;
  width: 100%;
  font-size: 1.5rem;
  margin-bottom: 32px;
}